<template>
    <div class="wrapper">
        <b-row style="width: 100%;">
            <div style="height: 600px;position: relative;margin-right: auto;margin-left: auto;">
                <video-player  class="video-player-box"
                        ref="videoPlayer"
                        :options="playerOptions"
                        :playsinline="true"
                        customEventName="customstatechangedeventname">
                </video-player>

                <div id="overlays-wrap">
                    <div class="overlay-item" v-if="pollOptions.started">
                        <p class="vo-question">Want to become a partner?</p>
                        <b-button style="width: 40%" variant="success" @click="pollOptions.started = false">START</b-button>
                    </div>
                    <div class="overlay-item" data-overlayid="59" data-time="41" v-else-if="!pollOptions.completed">
                        <p class="vo-question">
                        {{ pollOptions.questions[pollOptions.step].question }}
                        </p>
                        <div class="vtask-choices-wrap">
                            <div class="vtask-choice-item" v-for="answer in pollOptions.questions[pollOptions.step].answers">
                                <input class="vtask-choice" type="radio" name="quiz" id="59-a1" value="1" data-correct="0">
                                <label class="radio-tile" for="59-a1"  @click="vote(pollOptions.step, answer)">
                                <span class="radio-tile-label">
                                    {{ answer.answer }}
                                </span>
                                </label>
                            </div>
                            </div> <!-- vtask-choices-wrap -->
                        <!-- <a class="buttonb vtask-btn-continue">Continue</a> -->
                    </div> <!-- overlay-item -->
                    <div class="overlay-item" v-else-if="pollOptions.completed"> 
                        <p class="vo-question">Thank you for completing the survey!</p>
                        <p class="vo-question">Please enter the following information and we will contact you soon:</p>
                        <b-form-group
                            id="fieldsetHorizontal"
                            label-cols-sm="4"
                            label-cols-lg="3"
                            label="Name:"
                            label-for="inputHorizontal"
                        >
                            <b-form-input id="inputHorizontal"/>
                        </b-form-group>
                        <b-form-group
                            id="fieldsetHorizontal"
                            label-cols-sm="4"
                            label-cols-lg="3"
                            label="Email:"
                            label-for="inputHorizontal"
                        >
                            <b-form-input id="inputHorizontal"/>
                        </b-form-group>
                        <b-button variant="success" style="width: 50%;">Send</b-button>
                    </div>
                </div>
        </div>
        </b-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pollResult: 0,
                playerOptions: {
                    // videojs options
                    muted: true,
                    language: 'en',
                    sources: [{
                        type: "video/mp4",
                        src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
                    }],
                    autoplay: 'muted',
                    height: 500,
                    controls: false,
                    loop: true
                },
                pollOptions: {
                    step: 0,
                    started: true,
                    completed: false,
                    questions: [
                        {
                            question: '¿En el escenario de la educación y de la consultoría estratégica, cuál sería tu principal aportación al negocio?',
                            answers: [
                                {answer: 'Capacidad financiera +20.000 - +30000 - +50000)', points: 10},
                                {answer: 'Capacidad técnica y directiva', points: 3},
                                {answer: 'Capacidad operativa (equipo humano y oficinas)', points: 5},
                                {answer: 'Cartera de clientes', points: 5},
                                {answer: 'Otras ', points: 10},
                                {answer: 'Todas las citadas', points: 10}
                                ]
                        },
                        {
                            question: ' ¿Qué te motiva a crear y dirigir tu propia actividad profesional?',
                            answers: [
                                {answer: 'La actual situación laboral', points: 10},
                                {answer: 'La escasa perspectiva que hay en el trabajo por cuenta ajena', points: 8},
                                {answer: 'La posibilidad tener mayores ingresos que en un trabajo convencional', points: 6},
                                {answer: 'Me gusta por encima de todo trabajar para mí', points: 4},
                                {answer: 'Dispongo de los recursos necesario para empezar', points: 2},
                                {answer: 'Siempre he querido trabajar en mi propio negocio', points: 4},
                                {answer: 'No busco o no me interesa  un trabajo autónomo o de  empresario', points: 0},
                                ]
                        },
                        {
                            question: '¿Cuál de estos casos describe mejor tu punto de partida?',
                            answers: [
                                {answer: 'Nunca he tenido un negocio de cualquier tipo (lo que sea)', points: 10},
                                {answer: 'Nunca he tenido un negocio ONLINE (pero sí tradicional)', points: 8},
                                {answer: 'He intentado algo online, pero con CERO resultados', points: 6},
                                {answer: 'He hecho algo online con POCOS resultados', points: 4},
                                {answer: 'Ya tuve buenos resultados antes, pero ahora mismo nada', points: 2},
                                {answer: 'Tengo "buenos" resultados, pero quiero que sean mejores', points: 1},
                                {answer: 'Otro', points: 0}
                            ]
                        },
                        {
                            question: '¿Cuál es tu nivel de conocimientos de negocios digitales?',
                            answers: [
                                {answer: 'Neófito total. No sé prácticamente nada', points: 10},
                                {answer: 'Sé alguna que otra cosilla (embudos,SEO,afiliados,etc)', points: 8},
                                {answer: 'Elevados conocimientos (que nunca he puesto en práctica)', points: 6},
                                {answer: 'Elevado y además con práctica y experiencia', points: 4},
                            ]
                        },
                        {
                            question: '¿Qué crees que deberías MEJORAR para obtener buenos resultados con tu negocio online?',
                            answers: [
                                {answer: 'Mi mentalidad (creencias, preconceptos, miedos, etc.)', points: 10},
                                {answer: 'Conocimientos técnicos e informáticos', points: 8},
                                {answer: 'Conocimientos de negocio / administración de empresas', points: 6},
                                {answer: 'Pensamiento estratégico (saber tomar mejores decisiones)', points: 4},
                                {answer: 'Mi capacidad de invertir en herramientas, MKT, recursos, etc.', points: 2},
                                {answer: 'Otro', points: 1}
                            ]
                        },
                        {
                            question: '¿Cuál es (o ha sido) tu principal obstáculo?',
                            answers: [
                                {answer: 'Mi pareja/familia no me apoya', points: 10},
                                {answer: 'Me aburro muy rápido/pierdo las ganas', points: 8},
                                {answer: 'Me distraigo con otras oportunidades', points: 6},
                                {answer: 'Muy poco dinero para invertir', points: 4},
                                {answer: 'No contar con un mentor', points: 2},
                                {answer: 'Me lo pienso demasiado (parálisis por análisis, indecisión)', points: 1},
                            ]
                        },
                        {
                            question: '¿Confías en ti Cle?',
                            answers: [
                                {answer: 'Sí! Tengo plena confianza en mis capacidades', points: 10},
                                {answer: 'Más o menos. Tengo mis dudas', points: 8},
                                {answer: 'Muy poco. He fallado demasiado', points: 6},
                            ]
                        },
                        {
                            question: '¿Cuál es el grado de interés y motivación que tienes en este momento?',
                            answers: [
                                {answer: '1',  points: 1},
                                {answer: '2',  points: 2},
                                {answer: '3',  points: 3},
                                {answer: '4',  points: 4},
                                {answer: '5',  points: 5},
                                {answer: '6',  points: 6},
                                {answer: '7',  points: 7},
                                {answer: '8',  points: 8},
                                {answer: '9',  points: 9},
                                {answer: '10', points: 10}
                            ]
                        }
                    ]
                }
            }
        },
        methods: {
            vote(step, answer) {
                console.log(this.pollResult);
                this.pollResult += answer.points;
                this.pollOptions.step = step + 1;
                if(this.pollOptions.questions.length == this.pollOptions.step) {
                    this.pollOptions.completed = true;
                    alert("Result: " + this.pollResult);
                }
            }
        },
    }
</script>

<style scroped>
/*  */
#overlays-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height:500px;
    left: 0;
    top: 0;
    z-index: 2147483647;
}

.overlay-item {
    position: absolute;
    top: 0;
    color: #FFF;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    padding: 0;
    z-index: 2147483647;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;
}

.overlay-item .vo-question {
    margin: 10px 0 40px 0;
    width: 80%;
    text-align: center;
    line-height: 1.5;
}

.vtask-choices-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    max-width: 1000px;
    min-height: 10rem;
}

.vtask-choice-item .vtask-choice {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #079ad9;
    border-radius: 5px;
    padding: 1rem;
    transition: transform 300ms ease;
    background: rgba(0,0,0,0.5);
    z-index: 500;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
}

.vtask-btn-continue {
    color: #FFFFFF!important;
    border-color: #2B78C5;
    border-bottom-color: #2a65a0;
    text-decoration: none;
    text-shadow: none;
    color: #FFF;
    background: #39F;
    margin-top: 40px;
    padding: 10px 20px;
    font-family: Arial,sans-serif;
    font-size: 16px;
}

.vtask-choices-wrap {
    flex-wrap: wrap;
}
</style>